import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  createApolloClient,
  restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client';

import store from '@/store';

import { onError } from '@apollo/client/link/error';
import { ApolloLink, from, HttpLink, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/core';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const REFRESH_TOKEN = 'apollo-refresh-token';

// Custom Apollo Link to add x-hasura-user-id header
const logHeadersLink = new ApolloLink((operation, forward) => {
  // Get the user ID from your authentication state or wherever it's stored
  const storedData = localStorage.getItem('vc-store');

  const vs = JSON.parse(storedData);
  const userId = vs.auth.user.id;
  const companyId = vs.auth.user.lastAccessedCompanyId;
  const secret = 'RABBIT';

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      'x-hasura-admin-secret': secret,
      'x-hasura-company-id': companyId,
      'x-hasura-user-id': userId,
      'x-hasura-role': 'DEFAULT',
    },
  }));

  return forward(operation); // Pass the operation along
});

const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP ||
  'https://data.vibrantcreator.com/v1/graphql';
// 'https://vibrant-creator-graphql-core.herokuapp.com/v1/graphql';

const httpLink = new HttpLink({
  uri: httpEndpoint,
});

const getStoredToken = () => {
  const { auth } = JSON.parse(localStorage.getItem('vc-store')) || {};
  return auth.token;
};

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri:
    process.env.VUE_APP_GRAPHQL_WS ||
    'wss://vibrantcreator-data-core-dev.herokuapp.com/v1/graphql',
  options: {
    reconnect: true, // Automatically reconnect if the connection is lost
    connectionParams: async () => {
      const token = await getStoredToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
    lazy: true,
    disconnectionCallback: () => {
      console.log('Disconnected from socket');
    },
  },
});

// Use split to send data to each link depending on the kind of operation:
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // Send subscriptions to the WebSocket link
  httpLink // Send queries and mutations to the HTTP link
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((error) => {
      if (error.extensions.code === 'access-denied') {
        const vueInstance = window.vm;
        onLogout(apolloInstance);
        vueInstance.$router.push('/auth/login');
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const activityLogOperations = [
  { name: 'createFoundationTypeMutation', dataKey: 'insert_foundation_one' },
  { name: 'updateFoundationTypeMutation', dataKey: 'update_foundation' },
  {
    name: 'createBusinessModelByTypeMutation',
    dataKey: 'insert_business_model_one',
  },
  { name: 'updateBusinessModelMutation', dataKey: 'update_business_model' },
  {
    name: 'createCompetitionComponentMutation',
    dataKey: 'insert_competition_one',
  },
  { name: 'updateCompetitionComponentMutation', dataKey: 'update_competition' },
  {
    name: 'createMarketPotentialComponentMutation',
    dataKey: 'insert_market_potential_one',
  },
  {
    name: 'updateMarketPotentialComponentMutation',
    dataKey: 'update_market_potential_by_pk',
  },
  {
    name: 'createRiskAssessmentComponentMutation',
    dataKey: 'insert_risk_assessment_one',
  },
  {
    name: 'updateRiskAssessmentComponentMutation',
    dataKey: 'update_risk_assessment_by_pk',
  },
];

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const { operation: requestType } = getMainDefinition(operation.query);
    if (requestType == 'mutation') {
      const activityLogOperation = activityLogOperations.find(
        (op) => op.name === operation.operationName
      );
      if (activityLogOperation) {
        store.dispatch('company/logAction', {
          data: data.data,
          operation,
          activityLogOperation,
        });
      }
    }
    return data;
  });
});

const link = from([logHeadersLink, errorLink, responseLink, splitLink]);

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint:
    process.env.VUE_APP_GRAPHQL_WS ||
    'wss://vibrantcreator-data-core-dev.herokuapp.com/v1/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link,
  cache: new InMemoryCache(),

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: (tokenName) => {
    if (typeof window !== 'undefined') {
      let token = null;
      if (!location.href.includes('share')) {
        // get the authentication token from local storage if it exists
        token = window.localStorage.getItem(tokenName);
      }
      // return the headers to the context so httpLink can read them
      return token ? `Bearer ${token}` : '';
    }
  },

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export let apolloInstance = null;

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'network-only',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  apolloInstance = apolloProvider.defaultClient;

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, tokenDetails) {
  const token = tokenDetails.token;
  const refreshToken = tokenDetails.refreshToken;

  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
