import { render, staticRenderFns } from "./UsersYouCanMessage.vue?vue&type=template&id=344455dc&scoped=true"
import script from "./UsersYouCanMessage.vue?vue&type=script&lang=js"
export * from "./UsersYouCanMessage.vue?vue&type=script&lang=js"
import style0 from "./UsersYouCanMessage.vue?vue&type=style&index=0&id=344455dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344455dc",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
