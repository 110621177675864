import { gql } from '@apollo/client/core';

export const getChannelDetailsQuery = gql`
  query getChannelDetailsQuery($channelId: Int) {
    chat_channel(where: { id: { _eq: $channelId } }) {
      id
      name
      type
      logoUrl
      description
      created_by
      participants {
        id
        participant {
          id
          firstname
          lastname
          profilePhoto
          OnlineStatus
          companies {
            name
          }
        }
      }
    }
  }
`;

export const getAvailableOpenChannelsQuery = gql`
  query getAvailableOpenChannels($loggedInUser: Int!, $limit: Int) {
    chat_channel(
      limit: $limit
      where: {
        _and: [
          { type: { _eq: OPEN } }
          {
            participants_aggregate: {
              count: {
                predicate: { _eq: 0 }
                filter: { participant_id: { _eq: $loggedInUser } }
              }
            }
          }
        ]
      }
    ) {
      id
      name
      logoUrl
      description
      participants {
        id
      }
    }
  }
`;

export const getJoinedChannelListQuery = gql`
  query getJoinedChannelListQuery(
    $loggedInUserId: Int
    $limit: Int
    $offset: Int
  ) {
    chat_channel(
      where: { participants: { participant_id: { _eq: $loggedInUserId } } }
      order_by: { last_chat_time: desc_nulls_last }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      type
      logoUrl
      description
      participants {
        id
      }
      messages(order_by: { created_at: desc }, limit: 2) {
        id
        content
        created_at
        sender {
          firstname
          lastname
          profilePhoto
          id
          OnlineStatus
        }
      }
      messages_aggregate {
        aggregate {
          count
        }
      }
    }
    chat_channel_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const subscribeToJoinedChannelListQuery = gql`
  subscription subscribeToJoinedChannelListQuery($loggedInUserId: Int) {
    chat_channel(
      where: { participants: { participant_id: { _eq: $loggedInUserId } } }
      order_by: { last_chat_time: desc_nulls_last }
      limit: 50
      offset: 0
    ) {
      id
      name
      logoUrl
      description
      type
      participants {
        id
      }
      messages(order_by: { created_at: desc }, limit: 2) {
        id
        content
        created_at
        sender {
          firstname
          lastname
          profilePhoto
          id
          OnlineStatus
        }
      }
      messages_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const getChannelMessagesQuery = gql`
  query getChannelMessagesQuery($channelId: Int, $limit: Int, $offset: Int) {
    chat_channel_message(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { channel_id: { _eq: $channelId } }
    ) {
      content
      id
      created_at
      updated_at
      sender {
        profilePhoto
        lastname
        firstname
        id
        OnlineStatus
      }
    }
    chat_channel_message_aggregate(where: { channel_id: { _eq: $channelId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const subscribeToChannelMessagesQuery = gql`
  subscription subscribeToChannelMessagesQuery($channelId: Int) {
    chat_channel_message(
      limit: 15
      offset: 0
      order_by: { created_at: desc }
      where: { _and: { channel_id: { _eq: $channelId } } }
    ) {
      content
      id
      created_at
      updated_at
      sender {
        profilePhoto
        lastname
        firstname
        id
        OnlineStatus
      }
    }
  }
`;

export const getUserChatListQuery = gql`
  query getUserChatListQuery($loggedInUserId: Int) {
    personal_chat_relationship(
      limit: 50
      offset: 0
      order_by: { last_chat_time: desc }
      where: {
        _or: [
          { participant1: { _eq: $loggedInUserId } }
          { participant2: { _eq: $loggedInUserId } }
        ]
      }
    ) {
      id
      person1 {
        id
        firstname
        lastname
        profilePhoto
        OnlineStatus
      }
      person2 {
        firstname
        id
        lastname
        profilePhoto
        OnlineStatus
      }
      messages(limit: 2, offset: 0, order_by: { created_at: desc }) {
        id
        created_at
        content
        IsRead
        creatorId
        recipientId
        sender {
          email
          firstname
          lastname
          id
          profilePhoto
          OnlineStatus
        }
        relation {
          messages_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      last_chat_time
      messages_aggregate(
        where: {
          _and: [
            { IsRead: { _eq: false } }
            { recipientId: { _eq: $loggedInUserId } }
          ]
        }
      ) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`;

export const subscribeUserChatListQuery = gql`
  subscription subscribeUserChatListQuery($loggedInUserId: Int) {
    personal_chat_relationship(
      limit: 50
      offset: 0
      order_by: { last_chat_time: desc }
      where: {
        _or: [
          { participant1: { _eq: $loggedInUserId } }
          { participant2: { _eq: $loggedInUserId } }
        ]
      }
    ) {
      id
      person1 {
        id
        firstname
        lastname
        profilePhoto
        OnlineStatus
      }
      person2 {
        firstname
        id
        lastname
        profilePhoto
        OnlineStatus
      }
      messages(limit: 2, offset: 0, order_by: { created_at: desc }) {
        id
        created_at
        content
        IsRead
        creatorId
        recipientId
        sender {
          email
          firstname
          lastname
          id
          profilePhoto
          OnlineStatus
        }
        relation {
          messages_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      last_chat_time
      messages_aggregate(
        where: {
          _and: [
            { IsRead: { _eq: false } }
            { recipientId: { _eq: $loggedInUserId } }
          ]
        }
      ) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`;

// RETRIEVE MESSAGES WITHIN A CHAT
export const getOneOnOneMessagesQuery = gql`
  query getOneOnOneMessagesQuery(
    $loggedInUser: Int
    $profileUserId: Int
    $limit: Int
    $offset: Int
  ) {
    personal_chat_message(
      limit: $limit
      offset: $offset
      order_by: { created_at: asc_nulls_last }
      where: {
        _or: [
          {
            _and: [
              { creatorId: { _eq: $loggedInUser } }
              { recipientId: { _eq: $profileUserId } }
            ]
          }
          {
            _and: [
              { creatorId: { _eq: $profileUserId } }
              { recipientId: { _eq: $loggedInUser } }
            ]
          }
        ]
      }
    ) {
      content
      id
      creatorId
      IsRead
      recipientId
      relationshipId
      created_at
      sender {
        createdAt
        firstname
        id
        lastname
        profilePhoto
        OnlineStatus
      }
      relation {
        messages_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const subscribeToOneOnOneMessagesQuery = gql`
  subscription subscribeToOneOnOneMessagesQuery(
    $loggedInUser: Int
    $profileUserId: Int
    $limit: Int
    $offset: Int
  ) {
    personal_chat_message(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        _or: [
          {
            _and: [
              { creatorId: { _eq: $loggedInUser } }
              { recipientId: { _eq: $profileUserId } }
            ]
          }
          {
            _and: [
              { creatorId: { _eq: $profileUserId } }
              { recipientId: { _eq: $loggedInUser } }
            ]
          }
        ]
      }
    ) {
      content
      IsRead
      id
      creatorId
      recipientId
      relationshipId
      created_at
      sender {
        createdAt
        firstname
        id
        lastname
        profilePhoto
        OnlineStatus
      }
      relation {
        messages_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const getUnreadMessageCountInChannel = gql`
  query getUnreadMessageCountInChannel($loggedInUserId: Int!) {
    channel_unread_message(
      where: { participant_id: { _eq: $loggedInUserId } }
    ) {
      noOfUnread
      channel_id
    }
  }
`;

// export const subscribeToUnreadMessages = gql`
// subscription subscribeToUnreadMessages($userId: Int!) {
//   personal_chat_message_aggregate(where: {recipientId: {_eq: $userId}, IsRead: {_eq: false}}) {
//     aggregate {
//       count
//     }
//   }
//   channel_unread_message_aggregate(where: {participant_id: {_eq: $userId}}) {
//     aggregate {
//       sum {
//         noOfUnread
//       }
//     }
//   }
// `;
