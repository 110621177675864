import { render, staticRenderFns } from "./ProfileSettingsDrawer.vue?vue&type=template&id=345acc48"
import script from "./ProfileSettingsDrawer.vue?vue&type=script&lang=js"
export * from "./ProfileSettingsDrawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CAvatar: require('@chakra-ui/vue').CAvatar, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CStack: require('@chakra-ui/vue').CStack, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
