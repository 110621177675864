import { gql } from '@apollo/client/core';

export const getSubscriptionPlansQuery = gql`
  query getSubscriptionPlansQuery {
    plan {
      updatedAt
      name
      id
      createdAt
      plan_prices {
        updatedAt
        price
        stripeId
        frequency
        id
        createdAt
      }
    }
  }
`;

export const getCardsQuery = gql`
  query getCardsQuery($userId: Int!) {
    cards(where: { userId: { _eq: $userId } }) {
      updatedAt
      createdAt
      expiredDate
      name
      last4
      bin
      token
      userId
      isExpired
      id
    }
  }
`;

export const getActiveSubscriptionQuery = gql`
  query getActiveSubscriptionQuery($companyId: Int!) {
    billing_subscription(
      where: { status: { _eq: "active" }, companyId: { _eq: $companyId } }
    ) {
      companyId
      planId
      id
      currentStartDate
      currentEndDate
      stripeSubscriptionId
      status
      priceId
    }
  }
`;

export const getPlanPriceQuery = gql`
  query getPlanPriceQuery($stripeId: String!) {
    plan_price(where: { stripeId: { _eq: $stripeId } }) {
      price
      planId
      stripeId
      frequency
      id
      plan {
        name
        id
        createdAt
        updatedAt
      }
    }
  }
`;

export const getBillingHistoryQuery = gql`
  query getBillingHistoryQuery($companyId: String!) {
    billing_history(where: { companyId: { _eq: $companyId } }) {
      actualDuration
      actualPrice
      amount
      companyId
      dateCharged
      plan
      validfrom
      validto
      id
    }
  }
`;

export const getCreditChargesQuery = gql`
  query getCreditChargesQuery($chargeType: String!) {
    credit_charges(where: { chargeType: { _eq: $chargeType } }) {
      id
      amount
      chargeType
      description
      quantity
    }
  }
`;
