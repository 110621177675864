import { render, staticRenderFns } from "./JoinedChannelsList.vue?vue&type=template&id=3f814bc3&scoped=true"
import script from "./JoinedChannelsList.vue?vue&type=script&lang=js"
export * from "./JoinedChannelsList.vue?vue&type=script&lang=js"
import style0 from "./JoinedChannelsList.vue?vue&type=style&index=0&id=3f814bc3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f814bc3",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CSpinner: require('@chakra-ui/vue').CSpinner, CText: require('@chakra-ui/vue').CText, CBadge: require('@chakra-ui/vue').CBadge, CBox: require('@chakra-ui/vue').CBox})
