var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',[_c('c-drawer',{attrs:{"isOpen":_vm.isOpen,"placement":"left","on-close":() => {
        _vm.$emit('close');
      }}},[_c('c-drawer-overlay'),_c('c-drawer-content',{attrs:{"left":"6rem"}},[_c('c-drawer-header',[_vm._v("Profile")]),_c('c-drawer-body',[_c('form',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '80%',
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }),expression:"{\n            w: '80%',\n            mx: 'auto',\n            display: 'flex',\n            flexDirection: 'column',\n            alignItems: 'center',\n          }"}],on:{"submit":function($event){$event.preventDefault();return _vm.onFormSubmit.apply(null, arguments)}}},[_c('c-box',{attrs:{"w":"max-content","h":"max-content","position":"relative","mb":"8"}},[_c('c-avatar',{attrs:{"size":"2xl","name":`${_vm.user.firstname} ${_vm.user.lastname}`,"src":_vm.user.profilePhoto,"borderWidth":"2px","borderColor":"vc-orange.400"}}),_c('c-button',{attrs:{"zIndex":"2","bg":"#fff","p":"0","w":"10","h":"10","position":"absolute","bottom":"0","right":"0","variant":"outline","variant-color":"vc-orange","borderRadius":"50%"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[(!_vm.isUploadingImage)?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: 4,
                  h: 4,
                  fill: 'vc-orange.400',
                }),expression:"{\n                  w: 4,\n                  h: 4,\n                  fill: 'vc-orange.400',\n                }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-upload.svg") + "#upload"}})]):_c('c-spinner',{attrs:{"color":"vc-orange.400","thickness":"2px"}})],1),_c('input',{ref:"fileInput",attrs:{"hidden":"","type":"file","accept":"image/*"},on:{"change":_vm.onFileInputChange}})],1),_c('c-stack',{attrs:{"w":"100%","mb":"2em","spacing":3}},[_c('c-box',[_c('c-text',{attrs:{"fontSize":"sm","color":"gray.600"}},[_vm._v("First name")]),_c('c-input-group',{attrs:{"size":"lg"}},[_c('c-input-left-element',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      w: 4,
                      h: 4,
                    }),expression:"{\n                      w: 4,\n                      h: 4,\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-name.svg") + "#icon_name"}})])]),_c('c-input',{attrs:{"size":"lg","borderRadius":"10px","type":"text","placeholder":"First name"},model:{value:(_vm.$v.userForm.firstname.$model),callback:function ($$v) {_vm.$set(_vm.$v.userForm.firstname, "$model", $$v)},expression:"$v.userForm.firstname.$model"}})],1)],1),_c('c-box',[_c('c-text',{attrs:{"fontSize":"sm","color":"gray.600"}},[_vm._v("Last name")]),_c('c-input-group',{attrs:{"size":"lg"}},[_c('c-input-left-element',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      w: 4,
                      h: 4,
                    }),expression:"{\n                      w: 4,\n                      h: 4,\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-name.svg") + "#icon_name"}})])]),_c('c-input',{attrs:{"size":"lg","borderRadius":"10px","type":"text","placeholder":"Last name"},model:{value:(_vm.$v.userForm.lastname.$model),callback:function ($$v) {_vm.$set(_vm.$v.userForm.lastname, "$model", $$v)},expression:"$v.userForm.lastname.$model"}})],1)],1),_c('c-box',[_c('c-text',{attrs:{"fontSize":"sm","color":"gray.600"}},[_vm._v("Email address")]),_c('c-input-group',{attrs:{"size":"lg"}},[_c('c-input-left-element',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      w: 4,
                      h: 4,
                    }),expression:"{\n                      w: 4,\n                      h: 4,\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-mail.svg") + "#icon_mail"}})])]),_c('c-input',{attrs:{"readonly":"","aria-readonly":"","size":"lg","borderRadius":"10px","type":"email","placeholder":"Email Address","value":_vm.user.email}})],1)],1)],1),_c('c-button',{attrs:{"type":"submit","w":"100%","size":"lg","disabled":_vm.$v.userForm.$invalid || _vm.isUpdatingUser,"variant":"solid","variant-color":"vc-orange"}},[_vm._v(" Save "),(_vm.isUpdatingUser)?_c('c-spinner',{attrs:{"color":"#fff","thickness":"2px"}}):_vm._e()],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }