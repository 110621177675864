<template>
  <c-box>
    <c-drawer
      :isOpen="isOpen"
      placement="left"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-drawer-overlay />
      <c-drawer-content left="6rem">
        <!-- <c-drawer-close-button /> -->
        <c-drawer-header>Profile</c-drawer-header>

        <c-drawer-body>
          <form
            v-chakra="{
              w: '80%',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }"
            @submit.prevent="onFormSubmit"
          >
            <c-box w="max-content" h="max-content" position="relative" mb="8">
              <c-avatar
                size="2xl"
                :name="`${user.firstname} ${user.lastname}`"
                :src="user.profilePhoto"
                borderWidth="2px"
                borderColor="vc-orange.400"
              />
              <c-button
                zIndex="2"
                bg="#fff"
                p="0"
                w="10"
                h="10"
                position="absolute"
                bottom="0"
                right="0"
                variant="outline"
                variant-color="vc-orange"
                borderRadius="50%"
                @click="$refs.fileInput.click()"
              >
                <svg
                  v-if="!isUploadingImage"
                  v-chakra="{
                    w: 4,
                    h: 4,
                    fill: 'vc-orange.400',
                  }"
                >
                  <use href="@/assets/icons/icon-upload.svg#upload"></use>
                </svg>
                <c-spinner v-else color="vc-orange.400" thickness="2px" />
              </c-button>
              <input
                hidden
                @change="onFileInputChange"
                type="file"
                accept="image/*"
                ref="fileInput"
              />
            </c-box>
            <c-stack w="100%" mb="2em" :spacing="3">
              <c-box>
                <c-text fontSize="sm" color="gray.600">First name</c-text>
                <c-input-group size="lg">
                  <c-input-left-element>
                    <svg
                      v-chakra="{
                        w: 4,
                        h: 4,
                      }"
                    >
                      <use href="@/assets/icons/icon-name.svg#icon_name"></use>
                    </svg>
                  </c-input-left-element>
                  <c-input
                    size="lg"
                    borderRadius="10px"
                    type="text"
                    placeholder="First name"
                    v-model="$v.userForm.firstname.$model"
                  />
                </c-input-group>
              </c-box>
              <c-box>
                <c-text fontSize="sm" color="gray.600">Last name</c-text>
                <c-input-group size="lg">
                  <c-input-left-element>
                    <svg
                      v-chakra="{
                        w: 4,
                        h: 4,
                      }"
                    >
                      <use href="@/assets/icons/icon-name.svg#icon_name"></use>
                    </svg>
                  </c-input-left-element>
                  <c-input
                    size="lg"
                    borderRadius="10px"
                    type="text"
                    placeholder="Last name"
                    v-model="$v.userForm.lastname.$model"
                  />
                </c-input-group>
              </c-box>
              <c-box>
                <c-text fontSize="sm" color="gray.600">Email address</c-text>
                <c-input-group size="lg">
                  <c-input-left-element>
                    <svg
                      v-chakra="{
                        w: 4,
                        h: 4,
                      }"
                    >
                      <use href="@/assets/icons/icon-mail.svg#icon_mail"></use>
                    </svg>
                  </c-input-left-element>
                  <c-input
                    readonly
                    aria-readonly
                    size="lg"
                    borderRadius="10px"
                    type="email"
                    placeholder="Email Address"
                    :value="user.email"
                  />
                </c-input-group>
              </c-box>
              <!-- <c-link color="vc-orange.400">
                <c-text fontSize="xs" fontWeight="500" textAlign="right">
                  Change Password
                </c-text>
              </c-link> -->
            </c-stack>
            <c-button
              type="submit"
              w="100%"
              size="lg"
              :disabled="$v.userForm.$invalid || isUpdatingUser"
              variant="solid"
              variant-color="vc-orange"
            >
              Save
              <c-spinner v-if="isUpdatingUser" color="#fff" thickness="2px" />
            </c-button>
          </form>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-box>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import { updateUser } from '@/services/user';
import { uploadFile } from '@/services/common';

export default {
  validations: {
    userForm: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userForm: {
        firstname: null,
        lastname: null,
      },
      isUpdatingUser: false,
      isUploadingImage: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  watch: {
    user() {
      this.setUserForm();
    },
    isOpen() {
      this.setUserForm();
    },
  },
  created() {
    this.setUserForm();
  },
  methods: {
    ...mapMutations({
      updateUserState: 'auth/updateUser',
    }),
    async onFormSubmit() {
      this.isUpdatingUser = true;
      try {
        const res = await updateUser({
          id: this.user.id,
          set: { ...this.userForm },
        });
        this.updateUserState({ ...res.data.update_user_by_pk });
        this.isUpdatingUser = false;
      } catch (e) {
        this.isUpdatingUser = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating profile data`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    setUserForm() {
      const { firstname, lastname } = this.user;
      this.userForm = { firstname, lastname };
    },
    async onFileInputChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.isUploadingImage = true;
        try {
          const res = await uploadFile({ file, type: 'image' });
          await updateUser({
            id: this.user.id,
            set: { profilePhoto: res.data.url },
          });
          this.updateUserState({ profilePhoto: res.data.url });
          this.isUploadingImage = false;
        } catch (e) {
          this.isUploadingImage = false;
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating profile picture`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>

<style></style>
