var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-flex',{attrs:{"bg":"#fff","overflow":"hidden","direction":"column","borderRadius":"8px","p":_vm.isExpanded ? '8' : '0',"w":_vm.isCardView ? '100%' : { lg: '40em', xs: '100%' },"h":_vm.isCardView ? '600px' : '35em',"transition":"width 200ms ease, height 200ms ease;","boxShadow":"1px 1px 4px rgba(75, 102, 171, 0.20)"}},[_c('c-flex',{attrs:{"h":"70px","bg":"vc-orange.300","align":"center","px":"4","justify":"center"}},[_c('c-heading',{attrs:{"ml":"auto","color":"#fff","fontSize":"xl","as":"h4"}},[_vm._v("Notes")]),_c('c-link',{attrs:{"ml":"auto","display":"flex"},on:{"click":_vm.onClose}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '20px',
          h: '20px',
          fill: '#fff',
        }),expression:"{\n          w: '20px',\n          h: '20px',\n          fill: '#fff',\n        }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close.svg") + "#close"}})])])],1),_c('c-flex',{attrs:{"flex":"1"}},[_c('c-flex',{attrs:{"w":"250px","borderRightWidth":"1px","direction":"column"}},[_c('c-box',{attrs:{"id":"section-list","flex":"1","maxHeight":"30em","overflowY":"scroll","v-chakra":{
          verticalAlign: 'bottom',
        }}},[_c('c-list',[(_vm.isFetchingSections)?_c('c-list-item',{attrs:{"pt":"16","display":"flex","justifyContent":"center"}},[_c('c-spinner',{attrs:{"color":"vc-orange.400","thickness":"2px"}})],1):_vm._e(),_vm._l((_vm.sections),function(section,sectionIndex){return _c('c-list-item',{key:section.id},[_c('c-pseudo-box',{staticClass:"section-list",attrs:{"bg":section.isActive ? 'vc-orange.100' : 'transparent',"color":section.isActive ? '#fff' : '',"fontWeight":section.isActive ? '600' : 'normal',"borderBottomWidth":"1px","cursor":"pointer","as":"a","display":"flex","alignItems":"center","pl":"4","py":"3"},on:{"click":function($event){return _vm.onSectionClick(sectionIndex)}}},[_vm._v(" "+_vm._s(section.name)+" "),_c('c-link',{attrs:{"ml":"auto","display":"flex","mr":"3"},on:{"click":function($event){$event.stopPropagation();return _vm.onSectionDelete(section)}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    w: '12px',
                    h: '12px',
                    fill: 'error',
                    display: 'none',
                  }),expression:"{\n                    w: '12px',\n                    h: '12px',\n                    fill: 'error',\n                    display: 'none',\n                  }"}],staticClass:"item__delete"},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close-fill.svg") + "#close"}})])])],1)],1)})],2),(_vm.assignedNotes.length)?_c('c-text',{attrs:{"ml":"2","my":"2","fontWeight":"500","fontSize":"sm"}},[_vm._v("Assigned Notes")]):_vm._e(),_c('c-list',_vm._l((_vm.assignedNotes),function(noteAccess,noteAccessIndex){return _c('c-list-item',{key:noteAccess.id},[_c('c-pseudo-box',{attrs:{"bg":'transparent',"color":'',"borderTopWidth":noteAccessIndex === 0 ? '1px' : '0',"borderBottomWidth":"1px","cursor":"pointer","as":"a","display":"block","pl":"4","py":"3"},on:{"click":function($event){return _vm.onNoteClick(noteAccess.note)}}},[_c('c-text',[_vm._v(_vm._s(noteAccess.note.title))]),_c('c-text',{attrs:{"as":"small"}},[_vm._v(" by "+_vm._s(noteAccess.note.user.firstname)+" "+_vm._s(noteAccess.note.user.lastname)+" ")])],1)],1)}),1)],1),_c('c-flex',{attrs:{"h":"60px","borderTopWidth":"1px","mt":"auto"}},[_c('c-input',{attrs:{"borderWidth":"0px","h":"100%","placeholder":"Create new section..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSectionInputEnter.apply(null, arguments)}},model:{value:(_vm.sectionForm.name),callback:function ($$v) {_vm.$set(_vm.sectionForm, "name", $$v)},expression:"sectionForm.name"}})],1)],1),_c('c-flex',{attrs:{"flex":"1","direction":"column"}},[_c('c-box',{attrs:{"flex":"1","maxHeight":"30em","overflowY":"scroll","v-chakra":{
          verticalAlign: 'bottom',
        }}},[(_vm.sections[_vm.activeSectionIndex])?_c('c-list',_vm._l((_vm.sections[_vm.activeSectionIndex].notes),function(note){return _c('c-list-item',{key:note.id},[_c('c-pseudo-box',{staticClass:"notelist",attrs:{"role":"group","borderBottomWidth":"1px","cursor":"pointer","as":"a","display":"flex","alignItems":"center","px":"3","py":"3"},on:{"click":function($event){return _vm.onNoteClick(note)}}},[_vm._v(" "+_vm._s(note.title)+" "),_c('c-link',{attrs:{"ml":"auto","display":"flex"},on:{"click":function($event){$event.stopPropagation();return _vm.onNoteDelete(note)}}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    w: '12px',
                    h: '12px',
                    fill: 'error',
                    display: 'none',
                  }),expression:"{\n                    w: '12px',\n                    h: '12px',\n                    fill: 'error',\n                    display: 'none',\n                  }"}],staticClass:"item__delete"},[_c('use',{attrs:{"href":require("@/assets/icons/icon-close-fill.svg") + "#close"}})])])],1)],1)}),1):_vm._e()],1),_c('c-flex',{attrs:{"h":"60px","borderTopWidth":"1px","mt":"auto"}},[_c('c-input',{attrs:{"borderWidth":"0px","h":"100%","placeholder":"Add new note title..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onNoteInputEnter.apply(null, arguments)}},model:{value:(_vm.noteForm.title),callback:function ($$v) {_vm.$set(_vm.noteForm, "title", $$v)},expression:"noteForm.title"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }