import {
  addFollowTagMutation,
  addPostCommentMutation,
  addPostLikeMutation,
  addPostSaveMutation,
  createPostMutation,
  updatePostMutation,
  updatePostCommentMutation,
  deletePostLikeMutation,
  deletePostSaveMutation,
} from '../graphql/mutations';
import {
  getMatchingTagsQuery,
  getMatchingUsersQuery,
  getPostsQuery,
  getCompanyPostsQuery,
  getSuggestedTagsQuery,
  getPostQuery, searchUserQuery, searchCompanyQuery, getUsersPostsQuery, searchChannelQuery
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const getMatchingUsers = (text) => {
  return apolloInstance.query({
    query: getMatchingUsersQuery,
    variables: { text },
    fetchPolicy: 'no-cache',
  });
};

export const searchUsers = ({search_param, offset, limit}) => {
  return apolloInstance.query({
    query: searchUserQuery,
    variables: { search_param, offset, limit },
    fetchPolicy: 'no-cache'
  })
}

export const searchCompany = ({search_param, limit, offset}) => {
  return apolloInstance.query({
    query: searchCompanyQuery,
    variables: { search_param, limit, offset },
    fetchPolicy: 'no-cache'
  })
}

export const searchChannel = ({search_param, loggedInUserId, limit, offset}) => {
  return apolloInstance.query({
    query: searchChannelQuery,
    variables: { search_param, loggedInUserId, limit, offset},
    fetchPolicy: 'no-cache'
  })
}
export const getMatchingTags = (text) => {
  return apolloInstance.query({
    query: getMatchingTagsQuery,
    variables: { text },
    fetchPolicy: 'no-cache',
  });
};

export const getSuggestedTags = (userId) => {
  return apolloInstance.query({
    query: getSuggestedTagsQuery,
    variables: { userId },
    fetchPolicy: 'no-cache',
  });
};

export const getCompanyPosts = ({ companyId, limit, offset }) => {
  return apolloInstance.query({
    query: getCompanyPostsQuery,
    fetchPolicy: 'no-cache',
    variables: { companyId, limit, offset },
  });
};

export const getPosts = ({ userId, limit, offset }) => {
  return apolloInstance.query({
    query: getPostsQuery,
    fetchPolicy: 'no-cache',
    variables: { userId, limit, offset },
  });
};

export const getUsersPosts = ({ userId, offset, limit }) => {
  return apolloInstance.query({
    query: getUsersPostsQuery,
    fetchPolicy: 'no-cache',
    variables: { userId, offset, limit }
  })
}

export const getPost = ({ id, userId }) => {
  return apolloInstance.query({
    query: getPostQuery,
    fetchPolicy: 'no-cache',
    variables: { id, userId },
  });
};

export const createPost = (data) => {
  return apolloInstance.mutate({
    mutation: createPostMutation,
    variables: { object: data },
  });
};

export const updatePost = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updatePostMutation,
    variables: { id, set },
  });
};

export const addPostLike = (data) => {
  return apolloInstance.mutate({
    mutation: addPostLikeMutation,
    variables: { object: data },
  });
};

export const deletePostLike = (id) => {
  return apolloInstance.mutate({
    mutation: deletePostLikeMutation,
    variables: { id },
  });
};

export const addPostComment = (data) => {
  return apolloInstance.mutate({
    mutation: addPostCommentMutation,
    variables: { object: data },
  });
};

export const updatePostComment = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updatePostCommentMutation,
    variables: { id, set },
  });
};

export const addPostSave = (data) => {
  return apolloInstance.mutate({
    mutation: addPostSaveMutation,
    variables: { object: data },
  });
};

export const deletePostSave = (id) => {
  return apolloInstance.mutate({
    mutation: deletePostSaveMutation,
    variables: { id },
  });
};

export const addFollowTag = (data) => {
  return apolloInstance.mutate({
    mutation: addFollowTagMutation,
    variables: { object: data },
  });
};
