import { render, staticRenderFns } from "./MessagingArea.vue?vue&type=template&id=86c3ee1e&scoped=true"
import script from "./MessagingArea.vue?vue&type=script&lang=js"
export * from "./MessagingArea.vue?vue&type=script&lang=js"
import style0 from "./MessagingArea.vue?vue&type=style&index=0&id=86c3ee1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86c3ee1e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CSpinner: require('@chakra-ui/vue').CSpinner, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CButton: require('@chakra-ui/vue').CButton})
