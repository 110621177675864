<template>
  <c-box>
    <c-drawer
      :isOpen="isOpen"
      placement="left"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-drawer-overlay />
      <c-drawer-content left="6rem">
        <c-drawer-header>Team</c-drawer-header>

        <c-drawer-body
          display="flex"
          overflowY="scroll"
          flexDirection="column"
          pb="8"
        >
          <c-box v-if="members.length" mb="14">
            <c-flex justify="space-between" mb="3">
              <c-text fontWeight="500" color="gray.400" fontSize="sm">
                Team members
              </c-text>
              <c-spinner
                v-if="isUpdatingTeam"
                color="blue.400"
                thickness="2px"
              />
            </c-flex>
            <c-stack :spacing="4">
              <c-flex align="center" v-for="member in members" :key="member.id">
                <c-avatar
                  size="lg"
                  :name="`${member.user.firstname} ${member.user.lastname}`"
                  :src="member.user.profilePhoto"
                  mr="4"
                />
                <c-flex flex="1" justify="space-between" align="center">
                  <c-stack :spacing="1">
                    <c-text fontWeight="500">
                      {{ member.user.firstname }} {{ member.user.lastname }}
                      <c-badge mx="2" variant-color="blue">
                        <template
                          v-if="member.user.id === activeCompany.createdBy"
                        >
                          Owner
                        </template>
                        <template
                          ml="2"
                          v-if="
                            member.role === 'ADMIN' &&
                            member.user.id !== activeCompany.createdBy
                          "
                        >
                          Admin
                        </template>
                        <template
                          ml="2"
                          v-if="
                            member.role !== 'ADMIN' &&
                            member.user.id === user.id
                          "
                        >
                          {{ member.accessType }} access
                        </template>
                      </c-badge>
                    </c-text>
                    <c-text fontSize="sm" color="gray.400">
                      {{ member.user.email }}
                    </c-text>
                  </c-stack>
                  <c-popover
                    placement="bottom"
                    v-if="
                      isCompanyAdmin &&
                      member.user.id !== activeCompany.createdBy
                    "
                    v-slot="{ onClose }"
                  >
                    <c-popover-trigger>
                      <c-link>
                        <svg
                          v-chakra="{
                            fill: 'gray.500',
                            width: '14px',
                            height: '14px',
                          }"
                        >
                          <use
                            href="@/assets/icons/dots-horizontal.svg#dots"
                          ></use>
                        </svg>
                      </c-link>
                    </c-popover-trigger>
                    <c-popover-content w="max-content">
                      <c-popover-body p="0">
                        <c-list>
                          <c-list-item fontSize="sm">
                            <c-pseudo-box
                              @click="toggleAdminRight(member, onClose)"
                              as="a"
                              display="flex"
                              px="4"
                              py="3"
                              cursor="pointer"
                            >
                              {{
                                member.role === 'ADMIN'
                                  ? 'Set Role as Normal'
                                  : 'Set Role as Admin'
                              }}
                            </c-pseudo-box>
                          </c-list-item>
                          <c-list-item fontSize="sm">
                            <c-pseudo-box
                              @click="editTeamMember(member, onClose)"
                              as="a"
                              display="flex"
                              px="4"
                              py="3"
                              cursor="pointer"
                            >
                              Change Access Type
                            </c-pseudo-box>
                          </c-list-item>
                          <c-list-item
                            v-if="isCompanyAdmin || isCompanyOwner"
                            fontSize="sm"
                          >
                            <c-pseudo-box
                              @click="removeTeamMember(member, onClose)"
                              as="a"
                              color="vc-orange.400"
                              display="flex"
                              px="4"
                              py="3"
                              cursor="pointer"
                            >
                              Remove from Team
                            </c-pseudo-box>
                          </c-list-item>
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>
                </c-flex>
              </c-flex>
            </c-stack>
          </c-box>
          <c-box v-if="waitingResponseMembers.length">
            <c-text fontWeight="500" color="gray.400" fontSize="sm" mb="3">
              Invited
            </c-text>
            <c-stack :spacing="4">
              <c-flex
                v-for="member in waitingResponseMembers"
                :key="member.id"
                align="center"
              >
                <c-avatar
                  size="lg"
                  :name="`${member.user.firstname} ${member.user.lastname}`"
                  :src="member.user.profilePhoto"
                  mr="4"
                />
                <c-flex flex="1" justify="space-between" align="center">
                  <c-stack :spacing="1">
                    <c-text fontWeight="500">
                      {{ member.user.firstname }} {{ member.user.lastname }}
                    </c-text>
                    <c-text fontSize="sm" color="gray.400">
                      {{ member.user.email }}
                    </c-text>
                  </c-stack>
                  <c-popover
                    placement="bottom"
                    v-if="isCompanyOwner"
                    v-slot="{ onClose }"
                  >
                    <c-popover-trigger>
                      <c-link>
                        <svg
                          v-chakra="{
                            fill: 'gray.500',
                            width: '14px',
                            height: '14px',
                          }"
                        >
                          <use
                            href="@/assets/icons/dots-horizontal.svg#dots"
                          ></use>
                        </svg>
                      </c-link>
                    </c-popover-trigger>
                    <c-popover-content w="max-content">
                      <c-popover-body p="0">
                        <c-list>
                          <!-- <c-list-item px="4" py="3" fontSize="sm">
                            Send Again
                          </c-list-item> -->
                          <c-list-item fontSize="sm">
                            <c-pseudo-box
                              @click="removeTeamMember(member, onClose)"
                              as="a"
                              color="vc-orange.400"
                              display="flex"
                              px="4"
                              py="3"
                              cursor="pointer"
                            >
                              Remove from Team
                            </c-pseudo-box>
                          </c-list-item>
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>
                </c-flex>
              </c-flex>
            </c-stack>
          </c-box>
          <c-button
            mt="auto"
            mx="auto"
            py="4"
            size="lg"
            variant="solid"
            variant-color="vc-orange"
            @click="isInviteModalOpen = true"
            v-if="isCompanyAdmin"
          >
            Invite new members
          </c-button>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
    <InviteModal
      :isOpen="isInviteModalOpen"
      :isEditMode="isEditMode"
      :editingMember="editingMember"
      :onClose="closeModal"
    />
    <DeleteTeamMember
      :isOpen="isDeleteModalOpen"
      :memberToDelete="editingMember"
      :onClose="closeDeleteModal"
    />
  </c-box>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

import { getCompanyMembers } from '@/services/foundation';
import InviteModal from '../GrowthFramework/components/foundation/InviteModal.vue';
import { updateTeamMember } from '@/services/company';
import DeleteTeamMember from './DeleteTeamMember.vue';

export default {
  components: { InviteModal, DeleteTeamMember },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFetchingMembers: false,
      isInviteModalOpen: false,
      isUpdatingTeam: false,
      editingMember: null,
      isEditMode: false,
      isDeleteModalOpen: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyAdmin', 'isCompanyOwner']),
    ...mapState({
      teamMembers: (state) => state.company.members,
      user: (state) => state.auth.user,
    }),
    members() {
      return this.teamMembers
        ? this.teamMembers.filter((member) => member.isAccepted)
        : [];
    },
    waitingResponseMembers() {
      return this.teamMembers
        ? this.teamMembers.filter((member) => !member.isAccepted)
        : [];
    },
  },
  created() {
    this.getTeamMembers();
  },
  methods: {
    ...mapMutations({
      setMembers: 'company/setMembers',
    }),
    ...mapActions({
      updateMember: 'company/updateMember',
    }),
    getTeamMembers() {
      this.isFetchingMembers = true;
      getCompanyMembers({
        companyId: this.activeCompany.id,
        isAdvisory: false,
      }).then((res) => {
        this.setMembers(res.data.team);
        this.isFetchingMembers = false;
      });
    },
    closeModal(hasInvited) {
      this.isInviteModalOpen = false;
      this.isEditMode = false;
      this.editingMember = null;
      if (hasInvited) {
        this.getTeamMembers();
      }
    },
    toggleAdminRight(member, closePopover) {
      this.isUpdatingTeam = true;
      const { id, role } = member;
      const newRole = role === 'NORMAL' ? 'ADMIN' : 'NORMAL';
      closePopover();
      updateTeamMember({
        id,
        set: { role: newRole },
      }).then(() => {
        const updatedMember = { ...member, role: newRole };
        this.updateMember(updatedMember);
        this.isUpdatingTeam = false;
      });
    },
    editTeamMember(member, closePopover) {
      this.isInviteModalOpen = true;
      this.isEditMode = true;
      this.editingMember = { ...member };
      closePopover();
    },
    removeTeamMember(member, closePopover) {
      this.isDeleteModalOpen = true;
      this.editingMember = { ...member };
      closePopover();
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
  },
};
</script>

<style></style>
