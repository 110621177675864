import { gql } from '@apollo/client/core';

export const userQuery = gql`
  query userQuery($id: Int!) {
    user_by_pk(id: $id) {
      bio
      phoneNumber
      firstname
      isVerified
      isBlocked
      id
      lastname
      profilePhoto
      updatedAt
      email
      createdAt
      coverImage
      OnlineStatus
      user_followers {
        following {
          id
          lastname
          firstname
          email
          profilePhoto
          phoneNumber
          bio
        }
      }
      companies {
        name
        city
        business_stage {
          name
          id
        }
        business_objective {
          value
          description
        }
        company_sectors {
          sectorId
          business_sector {
            name
            id
          }
        }
        country
        createdAt
        createdBy
        id
        logo
        coverImage
        investmentEtaMetric
        investmentEtaValue
        name
        objective
        customerSegments
        companyRegistrationType
        size
        createdBy
        stageId
        elevatorPitch
        company_followers{
          follower {
            bio
            email
            coverImage
            id
            firstname
            lastname
          }
        }
        teams {
          position
          role
          id
          department
          accessType
        }
      }
      teams(
        where: { isDeclined: { _eq: false } }
        order_by: { createdAt: asc }
      ) {
        position
        role
        id
        department
        isDeclined
        isAccepted
        companyId
        accessType
        userId
        user {
          id
          OnlineStatus
          lastname
          profilePhoto
          firstname
        }
        company {
          customerSegments
          companyRegistrationType
          city
          business_stage {
            name
            id
          }
          business_objective {
            value
            description
          }
          company_sectors {
            sectorId
            business_sector {
              name
              id
            }
          }
          country
          createdAt
          createdBy
          id
          logo
          coverImage
          elevatorPitch
          investmentEtaMetric
          investmentEtaValue
          name
          objective
          size
          stageId
          teams {
            position
            role
            id
            department
            accessType
          }
        }
      }
      business_model_assignments {
        businessModelId
        createdAt
        id
        userId
        business_model {
          companyId
          id
          priority
          type
          description
        }
      }
      company_sdg_assignments {
        companySDGId
        id
        updatedAt
        createdAt
        userId
        company_sdg {
          id
          companyId
          priority
          assumption
          evaluation
          contribution
        }
      }
      company_skill_assignments {
        company_skill {
          companyId
          id
          assumption
          priority
          progress
        }
        companySkillId
      }
      competition_assignments {
        competition {
          companyId
          id
          assumption
          description
          priority
          type
        }
        id
        competitionId
      }
      foundation_assignments {
        foundationId
        id
        foundation {
          id
          priority
          progress
          type
          description
          companyId
          companyId
        }
      }
      market_potential_assignments {
        market_potential {
          createdAt
          description
          id
          companyId
          progress
          type
          priority
          companyId
          otherDescription
        }
        id
        marketPotentialId
        createdAt
      }
      risk_assessment_assignments {
        createdAt
        id
        riskAssessmentId
        updatedAt
        risk_assessment {
          assumption
          companyId
          priority
          description
          id
          progress
          type
          updatedAt
        }
      }
      lastAccessedCompanyId
      page_visiteds {
        createdAt
        id
        route
        userId
      }
    }
  }
`;

// plan_price {
//   updatedAt
//   price
//   stripeId
//   frequency
//   id
//   createdAt
//   plan {
//     id
//     updatedAt
//     name
//     id
//     createdAt
//   }
// }

export const getComponentCommentsQuery = gql`
  query getComponentCommentsQuery($componentId: Int!, $type: String) {
    comment(
      where: {
        _and: [{ componentId: { _eq: $componentId } }, { type: { _eq: $type } }]
      }
      order_by: { createdAt: asc }
    ) {
      componentId
      createdAt
      id
      message
      updatedAt
      type
      userId
      isEdited
      isDeleted
      user {
        firstname
        lastname
        profilePhoto
        id
      }
    }
  }
`;

export const subscribeToComponentCommentsQuery = gql`
  subscription subscribeToComponentCommentsQuery(
    $componentId: Int!
    $userId: Int!
    $type: String!
  ) {
    comment(
      where: {
        _and: [
          { componentId: { _eq: $componentId } }
          { userId: { _neq: $userId } }
          { type: { _eq: $type } }
        ]
      }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      componentId
      createdAt
      id
      message
      updatedAt
      type
      userId
      user {
        firstname
        lastname
        profilePhoto
        id
      }
    }
  }
`;

export const getNotificationsQuery = gql`
  query getNotificationsQuery {
    notification(order_by: { createdAt: desc }) {
      userId
      read
      message
      id
      data
      user {
        firstname
      }
      createdAt
      updatedAt
    }
  }
`;

// get users list
export const getUsersQuery = gql`
  query getUsersQuery($limit: Int!) {
    user(limit: $limit) {
      id
      firstname
      lastname
      email
      profilePhoto
      OnlineStatus
      companies {
        name
      }
    }
  }
`;

export const getUserFollowersAggregateQuery = gql`
  query getUserFollowersAggregateQuery($userId: Int!) {
    user_follower_aggregate(where: { userId: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

// get users followers you list
export const getUsersFollowersQuery = gql`
    query getUsersFollowersQuery($userId: Int!, $limit: Int!, $offset: Int!) {
        user_follower(where: {userId: {_eq: $userId}}, limit: $limit, offset: $offset) {
            follower {
                id
                firstname
                lastname
                email
                OnlineStatus
                profilePhoto
                companies {
                    name
                }
            }
        }
        user_follower_aggregate(where: {userId: {_eq: $userId}}) {
            aggregate {
                count
            }
        }
    }
`;

// get users who you are following list
export const getUserFollowingQuery = gql`
  query getUserFollowingQuery($userId: Int!, $limit: Int!, $offset: Int!) {
    user_follower(where: { followerId: { _eq: $userId } }, limit: $limit, offset: $offset) {
      following {
        id
        firstname
        lastname
        email
        OnlineStatus
        profilePhoto
        companies {
          name
        }
      }
    }

    user_follower_aggregate(where: {followerId: {_eq: $userId}}) {
      aggregate {
        count
      }
    }
    
  }
`;

export const getPopularUserSuggestionQuery = gql`
  query getPopularUserSuggestion($loggedInUser: Int!, $limit: Int!) {
    user(
      where: {
        _and: [
          { id: { _neq: $loggedInUser } }
          {
            user_followers_aggregate: {
              count: {
                predicate: { _eq: 0 }
                filter: { followerId: { _eq: $loggedInUser } }
              }
            }
          }
        ]
      }
      limit: $limit
      order_by: { user_followers_aggregate: { count: desc_nulls_last } }
    ) {
      id
      firstname
      lastname
      email
      profilePhoto
      OnlineStatus
      companies {
        id
        name
        city
        country
      }
    }
  }
`;
