import { render, staticRenderFns } from "./TeamDrawer.vue?vue&type=template&id=5c631d0c"
import script from "./TeamDrawer.vue?vue&type=script&lang=js"
export * from "./TeamDrawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CText: require('@chakra-ui/vue').CText, CSpinner: require('@chakra-ui/vue').CSpinner, CFlex: require('@chakra-ui/vue').CFlex, CAvatar: require('@chakra-ui/vue').CAvatar, CBadge: require('@chakra-ui/vue').CBadge, CStack: require('@chakra-ui/vue').CStack, CLink: require('@chakra-ui/vue').CLink, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
