<template>
  <c-flex
    bg="#fff"
    overflow="hidden"
    direction="column"
    borderRadius="8px"
    :p="isExpanded ? '8' : '0'"
    :w="isCardView ? '100%' : { lg: '40em', xs: '100%' }"
    :h="isCardView ? '600px' : '35em'"
    transition="width 200ms ease, height 200ms ease;"
    boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)"
  >
    <c-flex h="70px" bg="vc-orange.300" align="center" px="4" justify="center">
      <!-- <c-link display="flex">
        <svg
          v-chakra="{
            w: '25px',
            h: '25px',
            fill: '#fff',
          }"
        >
          <use href="@/assets/icons/icon-search.svg#icon-search"></use>
        </svg>
      </c-link>-->
      <c-heading ml="auto" color="#fff" fontSize="xl" as="h4">Notes</c-heading>
      <c-link ml="auto" @click="onClose" display="flex">
        <svg
          v-chakra="{
            w: '20px',
            h: '20px',
            fill: '#fff',
          }"
        >
          <use href="@/assets/icons/icon-close.svg#close" />
        </svg>
      </c-link>
    </c-flex>
    <c-flex flex="1">
      <c-flex w="250px" borderRightWidth="1px" direction="column">
        <c-box
          id="section-list"
          flex="1"
          maxHeight="30em"
          overflowY="scroll"
          :v-chakra="{
            verticalAlign: 'bottom',
          }"
        >
          <c-list>
            <c-list-item
              v-if="isFetchingSections"
              pt="16"
              display="flex"
              justifyContent="center"
            >
              <c-spinner color="vc-orange.400" thickness="2px" />
            </c-list-item>
            <c-list-item
              v-for="(section, sectionIndex) in sections"
              :key="section.id"
            >
              <c-pseudo-box
                class="section-list"
                :bg="section.isActive ? 'vc-orange.100' : 'transparent'"
                :color="section.isActive ? '#fff' : ''"
                :fontWeight="section.isActive ? '600' : 'normal'"
                borderBottomWidth="1px"
                cursor="pointer"
                as="a"
                display="flex"
                alignItems="center"
                pl="4"
                py="3"
                @click="onSectionClick(sectionIndex)"
              >
                {{ section.name }}
                <c-link
                  ml="auto"
                  @click.stop="onSectionDelete(section)"
                  display="flex"
                  mr="3"
                >
                  <svg
                    class="item__delete"
                    v-chakra="{
                      w: '12px',
                      h: '12px',
                      fill: 'error',
                      display: 'none',
                    }"
                  >
                    <use href="@/assets/icons/icon-close-fill.svg#close" />
                  </svg>
                </c-link>
              </c-pseudo-box>
            </c-list-item>
          </c-list>
          <c-text
            v-if="assignedNotes.length"
            ml="2"
            my="2"
            fontWeight="500"
            fontSize="sm"
            >Assigned Notes</c-text
          >
          <c-list>
            <c-list-item
              v-for="(noteAccess, noteAccessIndex) in assignedNotes"
              :key="noteAccess.id"
            >
              <c-pseudo-box
                :bg="'transparent'"
                :color="''"
                :borderTopWidth="noteAccessIndex === 0 ? '1px' : '0'"
                borderBottomWidth="1px"
                cursor="pointer"
                as="a"
                display="block"
                pl="4"
                py="3"
                @click="onNoteClick(noteAccess.note)"
              >
                <c-text>{{ noteAccess.note.title }}</c-text>
                <c-text as="small">
                  by {{ noteAccess.note.user.firstname }}
                  {{ noteAccess.note.user.lastname }}
                </c-text>
              </c-pseudo-box>
            </c-list-item>
          </c-list>
        </c-box>
        <c-flex h="60px" borderTopWidth="1px" mt="auto">
          <c-input
            borderWidth="0px"
            h="100%"
            placeholder="Create new section..."
            v-model="sectionForm.name"
            @keyup.enter="onSectionInputEnter"
          />
        </c-flex>
      </c-flex>
      <c-flex flex="1" direction="column">
        <c-box
          flex="1"
          maxHeight="30em"
          overflowY="scroll"
          :v-chakra="{
            verticalAlign: 'bottom',
          }"
        >
          <c-list v-if="sections[activeSectionIndex]">
            <c-list-item
              v-for="note in sections[activeSectionIndex].notes"
              :key="note.id"
            >
              <c-pseudo-box
                class="notelist"
                role="group"
                borderBottomWidth="1px"
                cursor="pointer"
                as="a"
                display="flex"
                alignItems="center"
                px="3"
                py="3"
                @click="onNoteClick(note)"
              >
                {{ note.title }}
                <c-link
                  ml="auto"
                  @click.stop="onNoteDelete(note)"
                  display="flex"
                >
                  <svg
                    class="item__delete"
                    v-chakra="{
                      w: '12px',
                      h: '12px',
                      fill: 'error',
                      display: 'none',
                    }"
                  >
                    <use href="@/assets/icons/icon-close-fill.svg#close" />
                  </svg>
                </c-link>
                <!-- <c-pseudo-box
                  :mb="2"
                  display="none"
                  :_groupHover="{ display: 'flex' }"
                >

                </c-pseudo-box>-->
              </c-pseudo-box>
            </c-list-item>
          </c-list>
        </c-box>
        <c-flex h="60px" borderTopWidth="1px" mt="auto">
          <c-input
            borderWidth="0px"
            h="100%"
            v-model="noteForm.title"
            placeholder="Add new note title..."
            @keyup.enter="onNoteInputEnter"
          />
        </c-flex>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';

import {
  addNote,
  addNoteSection,
  deleteNote,
  getNoteSections,
  subscribeNoteSections,
  deleteNoteSection,
} from '@/services/notes';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    isCardView: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      isExpanded: false,
      sections: [],
      sectionForm: {
        name: null,
      },
      noteForm: {
        segmentId: null,
        title: null,
      },
      isAddingSection: false,
      isFetchingSections: false,
      activeSectionIndex: 0,
      assignedNotes: [],
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.isExpanded = this.isCardView;
    this.getSections();
  },
  mounted() {
    this.subscribeToNotes();
  },
  methods: {
    onExpand() {
      this.isExpanded = true;
    },
    collapse() {
      this.isExpanded = false;
    },
    onClose() {
      this.$emit('close');
    },
    async onSectionFormSubmit() {
      this.isAddingSection = true;
      const data = {
        userId: this.user.id,
        name: this.sectionForm.name,
      };
      try {
        const res = await addNoteSection(data);
        const sectionIndex =
          this.sections.push({
            ...res.data.insert_segment_one,
            isActive: false,
          }) - 1;
        this.onSectionClick(sectionIndex);
        this.isAddingSection = false;
        this.sectionForm.name = null;
      } catch (e) {
        this.isAddingSection = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while adding section, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async getSections(refresh = false) {
      this.isFetchingSections = true;
      const res = await getNoteSections({ userId: this.user.id });
      this.sections = res.data.segment.map((section) => {
        return {
          ...section,
          isActive: false,
          notes: section.notes.map((note) => {
            return {
              ...note,
              isActive: false,
            };
          }),
        };
      });
      this.assignedNotes = cloneDeep(res.data.note_access);
      if (this.sections.length && !refresh) {
        this.onSectionClick(0);
      }
      this.isFetchingSections = false;
    },
    subscribeToNotes() {
      subscribeNoteSections({ userId: this.user.id }).subscribe({
        next: (res) => {
          this.sections = res.data.segment.map((section) => {
            return {
              ...section,
              isActive: false,
              notes: section.notes.map((note) => {
                return {
                  ...note,
                  isActive: false,
                };
              }),
            };
          });
          this.assignedNotes = cloneDeep(res.data.note_access);
          if (this.sections.length) {
            this.onSectionClick(0);
          }
        },
      });
    },
    onNoteAdded(note) {
      const sectionIndex = this.sections.findIndex(
        (section) => section.id === note.segmentId
      );
      this.sections[sectionIndex].isActive = true;
      this.sections[sectionIndex].notes.push({ ...note, isActive: false });
    },
    onSectionClick(index) {
      this.sections.forEach((section) => {
        section.isActive = false;
      });
      this.sections[index].isActive = true;
      this.activeSectionIndex = index;
      this.noteForm.segmentId = this.sections[index].id;
    },
    onSectionInputEnter() {
      this.onSectionFormSubmit();
      var elem = document.getElementById('section-list');
      elem.scrollTop = elem.scrollHeight;
    },
    async onNoteFormSubmit() {
      this.isAddingNote = true;
      const data = { ...this.noteForm, userId: this.user.id };
      try {
        const sectionIndex = this.sections.findIndex(
          (section) => section.id == data.segmentId
        );
        if (sectionIndex == -1) {
          throw new Error('Please add a section to add a note');
        }
        const res = await addNote(data);
        this.sections[sectionIndex].notes.push({ ...res.data.insert_note_one });
        this.noteForm.title = null;
        this.isAddingNote = false;
      } catch (error) {
        this.isAddingNote = false;
        this.$toast({
          title: 'An error occurred.',
          description:
            error.message || `Error while adding note, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    onNoteInputEnter() {
      if (this.noteForm.title && this.noteForm.title.trim()) {
        this.onNoteFormSubmit();
      }
    },
    onNoteClick(note) {
      this.$emit('changeView', { type: 'note', payload: { ...note } });
    },
    onNoteDelete(note) {
      const sectionIndex = this.sections.findIndex(
        (section) => section.id === note.segmentId
      );

      const selectedSection = this.sections[sectionIndex];

      const noteIndex = selectedSection.notes.findIndex(
        (_note) => _note.id === note.id
      );

      this.sections[sectionIndex].notes.splice(noteIndex, 1);

      deleteNote(note.id)
        .then(() => {})
        .catch((error) => {
          this.sections[sectionIndex].notes.splice(noteIndex, 0, { ...note });

          this.$toast({
            title: 'An error occurred.',
            description:
              error.message || `Error while deleting note, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onSectionDelete(section) {
      const sectionIndex = this.sections.findIndex(
        (_section) => _section.id === section.id
      );

      this.sections.splice(sectionIndex, 1);

      deleteNoteSection(section.id)
        .then(() => {})
        .catch((error) => {
          this.sections.splice(sectionIndex, 0, { ...section });

          this.$toast({
            title: 'An error occurred.',
            description:
              error.message ||
              `Error while deleting note section, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.notelist {
  &:hover {
    .item__delete {
      display: block;
    }
  }
}

.section-list {
  &:hover {
    .item__delete {
      display: block;
    }
  }
}
</style>
