<template>
  <c-box>
    <c-drawer
      :isOpen="isOpen"
      placement="left"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <!-- <c-drawer-overlay /> -->
      <c-drawer-content left="6rem" bg="#2E3545" w="20rem">
        <c-drawer-body py="8" display="flex" flexDirection="column">
          <c-box mb="8">
            <c-text color="#fff" fontSize="sm">Your current company</c-text>
            <c-text color="#fff" fontSize="lg" fontWeight="600">
              {{ activeCompany ? activeCompany.name : '' }}
            </c-text>
          </c-box>
          <c-box mb="8" v-if="teams.length">
            <c-text color="#fff" fontSize="sm" mb="4">
              Switch to other companies
            </c-text>
            <c-stack :spacing="4">
              <c-flex align="center" v-for="team in teams" :key="team.id">
                <c-avatar
                  size="md"
                  :name="team.company.name"
                  :src="team.company.logo"
                  mr="4"
                  borderWidth="0px"
                  color="#fff"
                />
                <c-flex flex="1" justify="space-between" align="center">
                  <c-stack :spacing="1">
                    <c-text fontWeight="500" color="#fff">
                      {{ team.company.name }}
                    </c-text>
                  </c-stack>
                  <c-popover placement="bottom-end">
                    <c-popover-trigger>
                      <c-link>
                        <svg
                          v-chakra="{
                            fill: 'gray.500',
                            width: '14px',
                            height: '14px',
                          }"
                        >
                          <use
                            href="@/assets/icons/dots-horizontal.svg#dots"
                          ></use>
                        </svg>
                      </c-link>
                    </c-popover-trigger>
                    <c-popover-content w="max-content">
                      <c-popover-body p="0">
                        <c-list>
                          <c-list-item fontSize="sm">
                            <c-pseudo-box
                              display="flex"
                              as="a"
                              cursor="pointer"
                              px="4"
                              py="3"
                              @click="switchCompany(team.company)"
                            >
                              Make Current
                            </c-pseudo-box>
                          </c-list-item>
                          <!-- <c-list-item
                            px="4"
                            py="3"
                            color="vc-orange.400"
                            fontSize="sm"
                          >
                            Delete
                          </c-list-item> -->
                        </c-list>
                      </c-popover-body>
                    </c-popover-content>
                  </c-popover>
                </c-flex>
              </c-flex>
            </c-stack>
          </c-box>
          <c-box mb="8" v-if="invites.length">
            <c-flex justify="space-between" mb="4">
              <c-text color="#fff" fontSize="sm"> Invited </c-text>
              <c-spinner
                v-if="isUpdatingInvites"
                color="#fff"
                thickness="2px"
              />
            </c-flex>
            <c-stack :spacing="4">
              <c-flex align="center" v-for="invite in invites" :key="invite.id">
                <c-avatar
                  size="md"
                  :name="invite.company.name"
                  :src="invite.company.logo"
                  mr="4"
                  borderWidth="0px"
                  color="#fff"
                />
                <c-flex flex="1" justify="space-between" align="center">
                  <c-stack :spacing="1">
                    <c-text fontWeight="500" color="#fff">
                      {{ invite.company.name }}
                    </c-text>
                    <c-flex justify="space-between">
                      <c-link
                        color="green.400"
                        fontWeight="500"
                        mr="6"
                        fontSize="sm"
                        @click="acceptInvite(invite)"
                      >
                        Accept
                      </c-link>
                      <c-link
                        color="vc-orange.400"
                        fontWeight="500"
                        fontSize="sm"
                        @click="declineInvite(invite)"
                      >
                        Decline
                      </c-link>
                    </c-flex>
                  </c-stack>
                </c-flex>
              </c-flex>
            </c-stack>
          </c-box>
          <!-- v-if="isStaging" -->
          <c-button
            mt="auto"
            mx="auto"
            size="lg"
            w="100%"
            variant="solid"
            variant-color="vc-orange"
            @click="onCreateCompanyClick"
          >
            Add Connection
          </c-button>
          <c-button
            mt="2"
            mx="auto"
            size="lg"
            w="100%"
            variant="outline"
            variant-color="vc-orange"
            @click="onCreateCompanyClick"
          >
            Create New Company
          </c-button>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
    <c-alert-dialog :is-open="isSwitchingCompanies">
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-body>
          <c-flex
            textAlign="center"
            py="8"
            align="center"
            direction="column"
            justify="center"
          >
            <c-spinner mb="3" color="blue.400" thickness="2px" size="xl" />
            <c-text fontWeight="500">
              Switching To
              {{ switchingToCompany ? switchingToCompany.name : '' }}
            </c-text>
          </c-flex>
        </c-alert-dialog-body>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { updateTeamMember } from '@/services/company';
import cloneDeep from 'lodash.clonedeep';
import { updateUser } from '@/services/user';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUpdatingInvites: false,
      isSwitchingCompanies: false,
      switchingToCompany: null,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      companies: (state) => state.companies,
    }),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
    invites() {
      return this.user.teams.filter(
        (team) =>
          !team.isAccepted &&
          !team.isDeclined &&
          team.company.createdBy !== this.user.id
      );
    },
    teams() {
      return this.user.teams.filter(
        (team) => team.isAccepted && team.company.id !== this.activeCompany.id
      );
    },
  },
  methods: {
    ...mapMutations({
      updateUser: 'auth/updateUser',
    }),
    ...mapActions({
      resetCompanyState: 'company/resetCompanyState',
      resetTimeline: 'timeline/resetTimeline',
    }),
    acceptInvite(invite) {
      this.isUpdatingInvites = true;
      updateTeamMember({ id: invite.id, set: { isAccepted: true } })
        .then(() => {
          this.isUpdatingInvites = false;
          this.updateUserTeam({ ...invite, isAccepted: true });
        })
        .catch(() => {
          this.isUpdatingInvites = false;
        });
    },
    declineInvite(invite) {
      updateTeamMember({ id: invite.id, set: { isDeclined: true } })
        .then(() => {
          this.isUpdatingInvites = false;
          this.updateUserTeam({ ...invite, isDeclined: true });
        })
        .catch(() => {
          this.isUpdatingInvites = false;
        });
    },
    updateUserTeam(team) {
      const userTeams = cloneDeep(this.user.teams);
      const teamIndex = userTeams.findIndex((_team) => _team.id === team.id);
      userTeams[teamIndex] = { ...team };
      this.updateUser({ teams: userTeams });
    },
    async switchCompany(company) {
      this.switchingToCompany = { ...company };
      this.isSwitchingCompanies = true;
      await updateUser({
        id: this.user.id,
        set: { lastAccessedCompanyId: company.id },
      });
      this.resetCompanyState(company);
      this.resetTimeline();
      this.$nextTick(() => {
        setTimeout(() => {
          location.reload();
        }, 400);
      });
    },
    onCreateCompanyClick() {
      this.$router.push({ path: '/onboarding', query: { new_company: true } });
    },
  },
};
</script>

<style></style>
