<template>
  <div>
    <div class="chat_window" v-show="showMessages">
      <MessagingArea
        height="footerArea"
        :selectedChat="user"
        @close="closeMessage"
      />
    </div>
    <div class="quick_chat_footer" v-if="!showMessages">
      <div class="chat_user">
        <img :src="user.img ? user.img : placeHolderImage" alt="profile" />

        <span class="online_dot" v-if="user.OnlineStatus == 'true'"></span>
      </div>

      <p @click="openMessages">
        {{ user.name }}
      </p>

      <div class="badge_sec">
        <!-- <span class="custom_badge1">1</span> -->
        <span @click="closeQuickChat">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import MessagingArea from './MessagingArea.vue';
import { mapActions } from 'vuex';

export default {
  name: 'QuickChatFooter',
  props: {
    user: Object,
  },
  components: {
    MessagingArea,
  },

  data() {
    return {
      messageReady: false,
      placeHolderImage,
      showMessages: false,
      selectedChat: {},
    };
  },
  // created() {
  //   // this.selectedChat.user = this.user;
  //   // this.selectedChat.messages = this.user.messages;
  //   this.messageReady = true;
  // },
  // computed: {
  //   ...mapState('messaging', {
  //     selectedUserChat: (state) => state.selectedUserChat,
  //     selectedChannelChat: (state) => state.selectedChannelChat,
  //   }),
  // },
  methods: {
    ...mapActions({
      removeQuickChat: 'messaging/removeQuickChat',
    }),
    openMessages() {
      this.showMessages = !this.showMessages;
    },
    closeMessage() {
      this.showMessages = false;
    },
    closeQuickChat() {
      this.removeQuickChat(this.user.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat_window {
  background: whitesmoke;
  height: 550px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 5px 1px #3333;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 16.5vh;
  overflow: hidden;
  position: relative;
  bottom: 0px;
  transition: all 0.3s ease;
  width: 370px;
}

.quick_chat_footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e1e7f7;
  border-radius: 8px 8px 0px 0px;
  color: #002b49;
  cursor: pointer;
  padding: 10px 10px;
  margin-left: 6px;
  width: 250px;

  p {
    font-weight: bold;
    // margin: 0 5px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: 10px;
  }

  .chat_user {
    display: flex;
    flex-shrink: 0;

    img {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    .online_dot {
      height: 10px;
      width: 10px;
      background-color: #088b15;
      border-radius: 50%;
      display: inline-block;
      margin: 20px 0 0 -10px;
    }
  }

  .badge_sec {
    display: flex;
    align-items: center;

    .custom_badge1 {
      background: #f00;
      color: #fff !important;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      font-size: 0.6rem;
    }
  }
}
</style>
