import { render, staticRenderFns } from "./Notes.vue?vue&type=template&id=dd31b28c"
import script from "./Notes.vue?vue&type=script&lang=js"
export * from "./Notes.vue?vue&type=script&lang=js"
import style0 from "./Notes.vue?vue&type=style&index=0&id=dd31b28c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})
