<template>
  <div class="users_list" :id="heightId">
    <div class="search_input" v-if="searchable">
      <c-input-group>
        <c-input-left-element>
          <i class="fas fa-search"></i>
        </c-input-left-element>
        <c-input
          placeholder="Search people here"
          borderWidth="0"
          borderRadius="20"
          size="lg"
          variant="filled"
          type="text"
          :value="searchValue"
          @input.native="handleInput"
        />
      </c-input-group>
    </div>
    <div style="text-align: center" v-if="loading">
      <c-spinner ml="2" color="orange" thickness="2px" />
    </div>
    <div v-else-if="!loading">
      <div v-for="(user, index) in users" :key="index">
        <c-box
          d="flex"
          alignItems="center"
          justify-content="space-between"
          class="user_container"
        >
          <div class="user">
            <img
              :src="user.profilePhoto ? user.profilePhoto : placeHolderImage"
              alt="profile"
            />
            <div class="details">
              <h3>{{ user.firstname }} {{ user.lastname }}</h3>
              <small>{{ user.email }}</small>
              <p>
                {{ user.companyName }}
              </p>
            </div>
          </div>

          <c-button
            variant-color="vc-orange"
            class="hover_btn"
            variant="outline"
            size="xs"
            v-if="isSendMessage"
            @click="sendMessage(user)"
          >
            Message
          </c-button>

          <c-button
            size="md"
            variant-color="vc-orange"
            variant="link"
            v-if="showRemove"
            @click="removeFromList(user.id)"
          >
            Remove
          </c-button>
          <input
            v-if="showCheckbox"
            @change="selectUser"
            type="checkbox"
            :id="'userCheckbox' + index"
            v-model="selectedUsers"
            :value="user"
          />
        </c-box>
      </div>
    </div>
    <div v-else>
      <p>No recent conversation</p>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import store from '@/store';
import { getUsers } from '@/services/user.js';
import { searchUsers } from '@/services/insight';
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import { getDateFormat } from '@/helpers/utils.js';

export default {
  name: 'UsersLists',
  props: {
    type: String,
    showRemove: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    isQuickMessage: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    isSendMessage: {
      type: Boolean,
      default: false,
    },
    heightId: String,
    assignedUsers: Array,
  },
  data() {
    return {
      placeHolderImage,
      searchValue: '',
      loading: false,
      users: [],
      selectedUsers: [],
      authUserId: store.state.auth.user.id,
    };
  },
  created() {
    if (!this.assignedUsers && this.type == 'newUsers') {
      this.getUsers();
    }

    if (this.assignedUsers) {
      this.users = this.formatUsers(this.assignedUsers);
    }
  },
  methods: {
    getDateFormat,
    sendMessage(selectedUser) {
      this.$emit('messageUser', selectedUser);
      this.$emit('close');
    },
    async getUsers() {
      this.loading = true;
      try {
        const response = await getUsers(50);

        const users = response.data.user;

        const filteredUsers = users.filter(
          (user) => user.firstname && user.lastname && user.companies.length > 0
        );

        const formattedUsers = this.formatUsers(filteredUsers);

        this.users = formattedUsers;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    formatUsers(filteredUsers) {
      const formattedUsers = filteredUsers.map((user) => {
        return {
          ...user,
          name: user.firstname
            ? user.firstname + ' ' + user.lastname
            : 'No name',
          userId: user.id,
          companyName: user.companyName
            ? user.companyName
            : user.companies.length
            ? this.showAllCompanies(user.companies)
            : 'No company',
        };
      });
      return formattedUsers;
    },
    showAllCompanies(companies) {
      // return all the company names in a text seperated by comma
      return companies.map((company) => company.name).join(', ');
    },
    handleInput(event) {
      if (event.target) {
        this.searchValue = event.target.value;
        this.search(this.searchValue);
      }
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        // await this.getUsers();
        return;
      }
      await this.getUsersSearchResultsQuery();
    }, 400),
    async getUsersSearchResultsQuery() {
      const response = await searchUsers({
        search_param: '%' + this.searchValue + '%',
        offset: 0,
        limit: 7,
      });

      const search_users_result = response.data.user_search;
      this.users = search_users_result;
      this.loading = false;
    },
    removeFromList(userId) {
      this.$emit('removeSelectedUser', userId);
    },
    selectUser() {
      this.$emit('usersSelected', this.selectedUsers);
    },
  },
};
</script>

<style lang="scss" scoped>
#fixedHeight {
  height: 500px;
  overflow-y: scroll;
}
.users_list {
  .user_container {
    padding-right: 10px;
    &:hover {
      background: #e4eaf5;
      border-radius: 3px;
    }
    .user {
      display: flex;
      margin-bottom: 3px;
      cursor: pointer;
      padding: 8px 5px;

      // svg {
      //   background-color: rgb(220 227 240);
      //   padding: 5px;
      //   font-size: 30px;
      //   color: rgb(183 183 183);
      //   border-radius: 4px;
      //   margin-right: 10px;
      // }

      img {
        margin-right: 10px;
        width: 45px;
        height: 45px;
        object-fit: fill;
      }

      img.recent {
        border-radius: 50%;
      }
      img {
        border-radius: 8px;
      }

      .details {
        // display: flex;
        // align-items: center;

        h3 {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: var(--color-primary);

          width: 127px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        small {
          font-size: 9px;
        }

        p {
          margin-top: 3px;
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: var(--color-primary);

          height: 26px;
          width: 178px;
          overflow: hidden;
          text-overflow: ellipsis;

          small {
            font-weight: bold;
          }
        }
      }
    }
  }

  .follow_btn {
    display: flex;
    margin-top: 10px;
  }
}
</style>
